/* Shim for deprecated uvoAutocomplete. Only modifies html layout for compatibility */
$.fn.uvoAutocomplete =
  $.fn.uvoAutocomplete ||
  function() {
    return this.each(function(_, el) {
      if (el.uvoAutocompleteShimInitialized) return;
      el.uvoAutocompleteShimInitialized = true;
      const $el = $(el);
      const $wrapper = $('<div class="uvo_pref-uvoAutocomplete-wrapper" />');
      const $icon = $(
        '<span class="ui-icon ui-icon-triangle-1-s" style="position: absolute; cursor: pointer; right: 10px; top: 0px;" />',
      );
      $el.wrap($wrapper);
      $el.after($icon);
      $el.addClass('uvo_pref-uvoAutocomplete-input');
      $el.css({ appearance: 'none', '::-ms-expand': 'none' });
      $icon.css({ pointerEvents: 'none' });
    });
  };

/* =============================
*  NEW CALC INIT
================================ */
// SHORT CALC
$.getScript('/assets/js/calc.js', function() {
  if ($('[data-calc-mini]').length > 0) {
    new Calc({
      ID: '69',
      calculatorType: 'DefaultCalculator',
      format: 'html',
      environment: getEnvironment,
      options: {
        deafault: {
          deadlinesDefault: [68657],
          academicLevelDefault: 2,
          paperTypesDefault: 2,
          pagesDefault: 1,
        },
      },
    });
  }
});
/* =============================
*  end NEW CALC INIT
================================ */

const FontFaceObserver = require('fontfaceobserver');
const font = new FontFaceObserver('Jenna Sue');

font.load().then(function() {
  $('body').addClass('font-loaded');
});

/* ==========================================================================
   2. Custom JS for do-my-essay.org
   ========================================================================== */
$(function() {
  // for inquiry page
  $('.show-hide')
    .find('.hidden')
    .hide();
  $('#inq-done-more').click(function() {
    if (!$(this).hasClass('less')) {
      $(this)
        .parents('.show-hide')
        .find('.hidden')
        .slideDown(500);
      $(this)
        .text('Read less')
        .addClass('less');
    } else {
      $(this)
        .parents('.show-hide')
        .find('.hidden')
        .slideUp(500);
      $(this)
        .text('Read more')
        .removeClass('less');
    }
  });

  $.fn.toggle_menu = function() {
    $(this).on('click', function() {
      $(this)
        .next()
        .slideToggle();
      $(this).toggleClass('active');
      if ($(this).hasClass('t-menu')) {
        $('html, body').toggleClass('no-scroll');
        if ($('.t-menu').hasClass('active')) {
          $('.topmenu > .container').removeClass('fixed');
        } else {
          setTimeout(function() {
            $('.topmenu > .container').addClass('fixed');
          }, 400);
        }
      }
    });
  };
  $('.t-menu').toggle_menu();
  $('.services-btn').toggle_menu();

  const toggleMenuBtn = document.querySelector('.t-menu');

  function setMenuheight() {
    const screenHeight = window.innerHeight;
    const heightOfMenu = document.querySelector('.header-wrapper').offsetHeight;
    const heightOFMenuBlockOpened = screenHeight - heightOfMenu;
    const menuList = document.querySelector('.topmenu');
    setTimeout(function() {
      menuList.style.height = `${heightOFMenuBlockOpened + 1}px`;
    }, 410);
  }

  function toggleClass() {
    const topBlock = document.querySelector('.header-wrapper');
    if (window.innerWidth > 800) {
      topBlock.classList.add('header');
    } else if (window.innerWidth <= 800) {
      topBlock.classList.remove('header');
    }
  }

  toggleMenuBtn.addEventListener('click', function() {
    setMenuheight();
  });

  document.addEventListener('DOMContentLoaded', function() {
    toggleClass();
  });

  (function toggleListInit() {
    if (
      !document.querySelector('[data-page="frequently_asked_questions"]') &&
      !document.querySelector('[data-page="money_back_guarantee"]')
    )
      return false;

    function toggle_list() {
      $('.toggle-list .toggle-content').addClass('hide');
      $(document).on('click', '.toggle-list .toggle-link', function() {
        if (
          $(this)
            .next()
            .hasClass('hide')
        ) {
          $(this)
            .next()
            .slideDown(500);
        } else {
          $(this)
            .next()
            .slideUp(500);
        }
        $(this)
          .parent()
          .toggleClass('active');
        $(this)
          .next()
          .toggleClass('hide');
        return false;
      });
    }

    window.addEventListener('contentRendered', toggle_list);
  })();

  window.addEventListener('resize', function() {
    toggleClass();
    if (window.innerWidth < 800 && $('.t-menu').hasClass('active')) {
      setMenuheight();
    }
  });

  function isIE() {
    const ua = window.navigator.userAgent;
    const msie = ua.indexOf('MSIE ');
    const isIE = msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./);
    return isIE;
  }

  isIE();

  if (isIE) {
    (function() {
      toggleClass();
    })();
  }

  window.addEventListener('resize', function() {
    if (window.innerWidth < 800 && $('.t-menu').hasClass('active')) {
      setMenuheight();
    }
  });

  // table cell hover
  $('.price_table_default tbody td:not(:first-child)')
    .mouseenter(function() {
      const tr = $(this).parent('.price_table_default tbody tr');
      const Col = tr.find('td').index(this);
      const Span = $('.price_table_default tbody tr').index(tr.get(0));
      tr.find(`td:lt(${Col})`).addClass('hover');
      $(`.price_table_default tbody tr:lt(${Span})`)
        .find(`td:eq(${Col})`)
        .addClass('hover');
      $(this).addClass('hover-cell');
    })
    .mouseleave(function() {
      $('.price_table_default tbody td').removeClass('hover-cell hover');
    });
});
// first screen
document.addEventListener('DOMContentLoaded', function(event) {
  $('.bxslider-top').bxSlider({
    mode: 'horizontal',
    captions: true,
    pager: true,
    nextText: '<span class="icon" data-icon="i"></span>',
    prevText: '<span class="icon" data-icon="h"></span>',
    nextSelector: '#slider-next',
    prevSelector: '#slider-prev',
    onSliderLoad() {
      if ($(window).width() >= 768) {
        $('.header,.pr-boxes')
          .addClass('act')
          .find('.logo')
          .addClass('zindex');
        $('.slider-container .bx-wrapper').addClass('show');
      }
    },
  });
});

// slider
function slider() {
  $('.bxslider').bxSlider({
    mode: 'horizontal',
    captions: true,
    pager: false,
    minSlides: 1,
    maxSlides: $(window).width() > 768 ? 3 : 1,
    slideWidth: $(window).width() > 768 ? 600 : 768,
    shrinkItems: true,
    nextText: '<span class="icon" data-icon="h"></span>',
    prevText: '<span class="icon" data-icon="i"></span>',
    nextSelector: '#slider-prev',
    prevSelector: '#slider-next',
  });
}

function scrollToTop() {
  const btnToTop = document.querySelector('.scroll-to-top');
  const scrollTargetEl = document.getElementsByTagName('body')[0];

  if (btnToTop) {
    btnToTop.addEventListener('click', () => {
      scrollTargetEl.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    });
  }
}

document.addEventListener('DOMContentLoaded', function(event) {
  slider();
  scrollToTop();
});

$(window).resize(function() {
  slider();
  main();
});

function msieversion() {
  const ua = window.navigator.userAgent;
  const msie = ua.indexOf('MSIE ');
  const isIE = msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./);
  if (isIE) {
    var $topMpos = $('.topmenu');
    $topMpos.children().css('position', 'static');
  }
  if (!isIE) {
    $(window).scroll(function() {
      if ($(window).width() >= 800) {
        $topMpos = $('.topmenu');
        if ($topMpos.length > 0) {
          const topMpos = $topMpos.offset().top;
          const scrl = $(window).scrollTop();
          if (topMpos <= scrl && !isIE) {
            $('.topmenu > .container').addClass('fixed');
          } else {
            $('.topmenu > .container').removeClass('fixed');
          }
        }
      }
    });
  }
}

msieversion();

function main() {
  if ($(window).width() < 800) {
    $('.head-line__user-nav').append($('.topmenu-item .UserBlockWhois'));
    $('.topmenu > .container').removeClass('fixed');
    // $('.main-wrap').css('padding-top', '59px');
    // $('.header').addClass('fixed');
  } else {
    $('.topmenu > .container').removeClass('fixed');
    $('.pages .topmenu > .container').addClass('fixed');
    // $('.main-wrap').css('padding-top', '0');
    // $('.header').removeClass('fixed');
  }
  // collapse
  $('.collapse-tab header').click(function() {
    $(this)
      .next()
      .slideToggle();
    $(this)
      .parent()
      .toggleClass('active');
  });
  $('.collapse-tab .collapse-link').click(function() {
    if (
      $(this)
        .prev()
        .css('display') == 'block'
    ) {
      $(this)
        .prev()
        .slideUp();
      $(this)
        .parent()
        .removeClass('active');
    }
  });

  $(window).resize(function() {
    if ($(window).width() < 800) {
      $('.head-line__user-nav').append($('.topmenu-item .UserBlockWhois'));
    }
  });
}

$(window).on('load', function() {
  setTimeout(function() {
    if ($(window).width() < 800 && $('.head-line__user-nav').length > 0) {
      $('.head-line__user-nav').append($('.topmenu-item .UserBlockWhois'));
    }
  }, 500);
});

(function(window, document) {
  function init() {
    window.NotifyWidget({
      siteId: '69',
      container: '.NotifyWidget-banner',
    });
  }

  if (typeof window.NotifyWidget !== 'undefined') {
    init();
  } else {
    const script = document.createElement('script');
    script.src = '/assets/js/notifyWidget.min.js';
    script.async = true;
    script.onload = function() {
      init();
    };
    document.getElementsByTagName('head')[0].appendChild(script);
  }
})(window, document);

(function(window, document) {
  function init() {
    const element = document.getElementById('CommonPage');

    if (element) {
      const pageName = element.getAttribute('data-page');
      const siteId = element.getAttribute('data-site-id');
      const policies = element.getAttribute('data-policies');
      const policyId = element.getAttribute('data-policy-id');

      let widgetOptions = {
        siteId,
        pageName,
      };

      if (policies && policyId) {
        widgetOptions = { siteId, pageName, policies, policyId };
      }

      window.commonPagesWidget.widget(element, widgetOptions);
    }
  }

  if (typeof window.commonPagesWidget !== 'undefined') {
    init();
  } else {
    const script = document.createElement('script');
    script.src = '/assets/js/commonPagesWidget.min.js';
    script.async = true;
    script.onload = function() {
      init();
    };
    document.getElementsByTagName('head')[0].appendChild(script);
  }
})(window, document);

// Function for the accordion list from FAQ section at the HOME page

(function faqAccordionList() {
  if (!document.querySelector('.section-faq')) {
    return;
  }

  function toggleAccordionList() {
    $('.toggle-list .toggle-content').addClass('hide');
    $(document).on('click', '.toggle-list .toggle-link', function() {
      if (
        $(this)
          .next()
          .hasClass('hide')
      ) {
        $(this)
          .next()
          .slideDown(500);
      } else {
        $(this)
          .next()
          .slideUp(500);
      }
      $(this)
        .parent()
        .toggleClass('active');
      $(this)
        .next()
        .toggleClass('hide');
      return false;
    });
  }

  window.addEventListener('DOMContentLoaded', toggleAccordionList);
})();
