import { observeElement } from '@uvocore-front/utils';
import Slider from '../Slider';
import siteJabberInit from './siteJabber';
import tabsInit from '../Tabs';
import './index.scss';

siteJabberInit();

observeElement({
  element: '.section-testimonials .splide',
  marginValue: 300,
  callback: () => {
    Slider('[data-splide-testimonials]', {
      perPage: 3,
      perMove: 1,
      gap: '24px',
      pagination: false,
      breakpoints: {
        1380: {
          perPage: 2,
          arrows: true,
          pagination: false,
        },
        1023: {
          perPage: 2,
          arrows: false,
          pagination: true,
        },
        767: {
          perPage: 2,
          arrows: false,
          pagination: true,
        },
        600: {
          perPage: 1,
          arrows: false,
          pagination: true,
        },
      },
    }).mount();
  },
});

observeElement({
  element: '.Tabs',
  marginValue: 100,
  callback: () => {
    const tab = document.querySelector('[data-tabs]');
    if (tab) tabsInit({ selector: tab, startPaneIndex: 1 });
  },
});
