import { observeElement } from '@uvocore-front/utils';
import Slider from '../../components/Slider';

observeElement({
  element: '.samples-slider',
  marginValue: 300,
  callback: () => {
    const slider = new Slider('.samples-slider', {
      perPage: 4,
      perMove: 1,
      gap: '24px',
      pagination: false,
      arrows: false,
      breakpoints: {
        1023: {
          perPage: 2,
          pagination: false,
        },
        767: {
          perPage: 2,
          pagination: true,
        },
        600: {
          perPage: 1,
          pagination: true,
        },
      },
    });

    slider.mount();
  },
});
