import { observeElement, getWidget } from '@uvocore-front/utils';
/**
 *  Calculator
 */
const isPageConfig =
  window.pageConfig &&
  Object.keys(window.pageConfig).length > 0 &&
  window.pageConfig.constructor === Object;

// Calculator Observer
observeElement({
  element: '[data-calc-short]',
  marginValue: 200,
  callback: () => {
    getWidget({
      widgetLink: '/assets/js/calc.js',
      loader: false,
      insertLoaderTo: '[data-calc-short]',
      loaderPosition: 'center',
      fn: () => {
        const pagePaperType =
          isPageConfig &&
          window.pageConfig.calculator !== undefined &&
          window.pageConfig.calculator.hasOwnProperty('pagePaperType') /* eslint-disable-line */
            ? window.pageConfig.calculator.pagePaperType
            : undefined;

        const calcOptions = {
          ID: '69',
          calculatorType: 'DefaultCalculator',
          format: 'json',
          parent: '[data-calc-short]',
          options: {
            deafault: {
              academicLevelDefault: 2,
            },
          },
        };

        if (pagePaperType !== undefined) {
          calcOptions.options.deafault.paperTypesDefault = pagePaperType;
        }

        if (window.Calc) {
          const calc = new Calc(calcOptions); /* eslint-disable-line */
        }
      },
    });
  },
});
